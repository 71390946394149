exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-aws-consultancy-jsx": () => import("./../../../src/pages/aws-consultancy.jsx" /* webpackChunkName: "component---src-pages-aws-consultancy-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-ecommerce-development-jsx": () => import("./../../../src/pages/ecommerce-development.jsx" /* webpackChunkName: "component---src-pages-ecommerce-development-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-app-development-jsx": () => import("./../../../src/pages/mobile-app-development.jsx" /* webpackChunkName: "component---src-pages-mobile-app-development-jsx" */),
  "component---src-pages-process-jsx": () => import("./../../../src/pages/process.jsx" /* webpackChunkName: "component---src-pages-process-jsx" */),
  "component---src-pages-product-consultancy-jsx": () => import("./../../../src/pages/product-consultancy.jsx" /* webpackChunkName: "component---src-pages-product-consultancy-jsx" */),
  "component---src-pages-qrinfo-jsx": () => import("./../../../src/pages/qrinfo.jsx" /* webpackChunkName: "component---src-pages-qrinfo-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-web-development-jsx": () => import("./../../../src/pages/web-development.jsx" /* webpackChunkName: "component---src-pages-web-development-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

